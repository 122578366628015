/* Built In Imports */

/* External Imports */
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders the should Refresh Component
 *
 * @param {string} url - Link
 * @returns {ReactElement} - should Refresh Component
 */

export const getCookie = name => {
  if (typeof window !== 'undefined') {
    // console.log('document-------',name, document.cookie);
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
};

export const handleUtmParams = (url, extraDomains) => {
  let domainList = [
    '//prs.innerengineering.com',
    '//prs.isha.in',
    '//sadhana.sadhguru.org',
    'mokshatwo.sushumna.isha.in',
    'goyip.netlify.app',
    'ishangam.isha.in',
    'msrsp.sadhguru.org',
    'ishaoutreach.org',
    'iycblr.sadhguru.org',
    'sp.isha.in',
    'uat-facade-sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
    'sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
    'ishaprs.sushumna.isha.in',
    'online.sadhguru.org',
    'online2.sadhguru.org',
    'registration.innerengineering.com',
    'qa-prs8.innerengineering.com',
    'lingabhairavi.org',
    'beta.lingabhairavi.org',
    'hys.sushumna.isha.in',
    'hys.isha.in',
    'isha.co',
    'poornanga.sushumna.isha.in',
    'poornanga.isha.in',
    'preprod-online.sadhguru.org',
    'uat-arpanam.sadhguru.org',
    'arpanam.sadhguru.org',
    'uat-lbofferings.sadhguru.org',
    'uat-arpanam.sadhguru.org',
  ];

  if (extraDomains?.length) {
    domainList = domainList.concat(extraDomains);
  }

  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  let newUrl = '';

  let isUrlAvailableForUtmParam = filter(domainList, function (domain) {
    return url.indexOf(domain) > -1;
  });

  if (isUrlAvailableForUtmParam.length) {
    let nUrl = new URL(url);
    let search_params = new URLSearchParams(nUrl.search);
    // let search_params = nUrl.searchParams;

    forEach(cookieParams, function (cookie) {
      let cookieVal = getCookie(cookie);

      if (cookieVal) {
        search_params.set(cookie, cookieVal);
      }
    });
    // change the search property of the main url
    nUrl.search = search_params.toString();

    // the new url string
    newUrl = nUrl.toString();
  } else {
    newUrl = url;
  }
  return newUrl;
};

export const refineUrlDomain = (
  url,
  region,
  lang,
  cardTitle = '',
  extraDomains
) => {
  let paramUrl = url;
  if (url?.indexOf('[envurl') > -1) {
    let updatedUrlArr = url
      .replace('][/envurl]', '')
      ?.replace('[envurl ', '')
      ?.split(' ');
    forEach(updatedUrlArr, function (upUrl) {
      if (config.ENV_NAME === 'production') {
        if (upUrl?.indexOf('prod=') > -1) {
          paramUrl = upUrl.replace("prod='", '').replace("'", '');
        }
      } else {
        if (upUrl?.indexOf('uat=') > -1) {
          paramUrl = upUrl.replace("uat='", '').replace("'", '');
        }
      }
    });
  }

  const checkRegionDomain = () => {
    const regions = ['in', 'global', 'au', 'ca', 'my', 'sg', 'uk', 'us'];
    const languages = ['en', 'hi', 'ta', 'te', 'kn', 'ml', 'mr'];

    let isMatched = false;
    forEach(regions, function (reg) {
      forEach(languages, function (language) {
        if (!isMatched) {
          isMatched =
            paramUrl?.toLowerCase().indexOf(`/${reg}/${language}/`) === 0;
        }
      });
    });
    // checking if it contains /en/ structure
    if (!isMatched && paramUrl?.toLowerCase().indexOf('/en/') > -1) {
      isMatched = true;
    }
    return isMatched;
  };

  let finalUrl = '';

  if (!paramUrl) {
    finalUrl = '#!';
  } else if (
    (paramUrl && paramUrl[0] === '#') ||
    paramUrl?.toLowerCase().indexOf('https://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('http://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('mailto:') >= 0
  ) {
    finalUrl = paramUrl;
  } else if (checkRegionDomain()) {
    finalUrl = `${paramUrl[0] === '/' ? '' : '/'}${paramUrl}`;
  } else {
    finalUrl = `/linga-bhairavi/${region}/${lang}${
      paramUrl[0] === '/' ? '' : '/'
    }${paramUrl}`;
  }
  if (finalUrl.endsWith('/')) {
    finalUrl = finalUrl.slice(0, -1);
  }
  // Add card section title in the url.
  if (cardTitle) {
    finalUrl = finalUrl + '?selection_source=' + cardTitle;
  }
  finalUrl = handleUtmParams(finalUrl, extraDomains);
  return finalUrl;
};

export const getEmbedUrl = url => {
  let retUrl = '';
  if (!url) {
    return retUrl;
  }
  if (url.indexOf('/watch') > -1) {
    retUrl = url.split('=')[1];
  } else if (url.indexOf('/embed/') > -1 || url.indexOf('/youtu.be/') > -1) {
    let splitArr = url.split('/');
    let splittedUrl = splitArr[splitArr.length - 1];
    retUrl =
      splittedUrl.split('?').length > 1
        ? splittedUrl.split('?')[0]
        : splittedUrl;
  } else {
    retUrl = url;
  }
  return retUrl;
};
