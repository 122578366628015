/* Built In Imports */
/* External Imports */
import mixpanel from 'mixpanel-browser';

/* Components */
import config from '@config';

/**
 *
 * @param {String} path
 * @returns
 */
export const mixPanel = path => {
  // console.log('mixPanel is', path, buildType);
  // Initialize Mixpanel with your project key

  const MIXPANEL_TOKEN = config.MIXPANEL_TOKEN;
  let mixPanelEnable = false;

  if (
    (path?.indexOf('inner-engineering') > -1 && path?.indexOf('/in/') > -1) ||
    path?.startsWith('/project-samskriti') ||
    path?.startsWith('/sadhguru-academy') ||
    path?.startsWith('/isha-home-school') ||
    path?.startsWith('/linga-bhairavi') ||
    path?.startsWith('/mahashivratri')
  ) {
    mixPanelEnable = true;
  }

  // console.log(
  //   'isIeoPage is',
  //   mixPanelEnable,
  //   config.ENV_NAME,
  //   path,
  //   MIXPANEL_TOKEN
  // );

  if (mixPanelEnable && MIXPANEL_TOKEN) {
    // console.log('mixpanel init starts', MIXPANEL_TOKEN, isIeoPage);

    mixpanel.init(MIXPANEL_TOKEN || '', {
      debug: config.MIXPANEL_DEBUG,
      track_pageview: false,
      ignore_dnt: true,
      persistence: 'localStorage',
    });
  }

  if (mixPanelEnable && mixpanel) {
    const action = {
      identify: id => {
        mixpanel.identify(id);
      },
      alias: id => {
        mixpanel.alias(id);
      },
      track: (name, props) => {
        mixpanel?.track(name, props);
      },
      people: () => {
        set: props => {
          mixpanel.people.set(props);
        };
      },
    };

    return action;
  }
  return {};
};
