/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useReducer, useState } from 'react';

/* External Imports */
import { Box, Link, ListItem, UnorderedList } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LoggedIn from '@components/NewHeader/LoggedIn';
import DesktopMenu from '@components/NewHeader/Menu/DesktopMenu';
import MobileMenu from '@components/NewHeader/Menu/MobileMenu';
import NotLoggedIn from '@components/NewHeader/NotLoggedIn';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { isNonRegionalized, refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */

const HeaderReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_COUNTRY':
      return {
        ...state,
        openCountry: !state.openCountry,
        openProfile: false,
        openMobileProfile: false,
      };
    case 'OPEN_PROFILE':
      return {
        ...state,
        openProfile: !state.openProfile,
        openCountry: false,
      };
    case 'OPEN_MOBILE_MENU':
      return {
        ...state,
        openMobileMenu: !state.openMobileMenu,
        openCountry: false,
        openProfile: false,
        openMobileProfile: false,
      };
    case 'CLOSE_DROPDOWN':
      return {
        ...state,
        openCountry: false,
        openProfile: false,
        openMobileProfile: false,
      };
    case 'OPEN_MOBILE_PROFILE':
      return {
        ...state,
        openMobileProfile: !state.openMobileProfile,
        openCountry: false,
      };
  }

  return {
    ...state,
    openCountry: false,
    openProfile: false,
    openMobileMenu: false,
    openMobileProfile: false,
  };
};

/**
 * Renders the Header component
 *
 * @param {string} headerMenuData - Profile Icon click handler.
 * @param {string} region -
 * @param {string} language -
 * @param {string} availableLocals - Country of the present page.
 * @param {string} currentUserRegion - Country of the present page.
 * @param {string} setCurrentUserRegion - Country of the present page.
 * @param {string} isStaticPage - Country of the present page.
 * @param {string} staticPageURL - Country of the present page.
 * @returns {ReactElement} Header component
 */
const Header = ({
  headerTopMenuData,
  headerMenuData,
  region,
  language,
  availableLocals = [],
  currentUserRegion,
  setCurrentUserRegion,
  isStaticPage,
  staticPageURL,
  page,
  secondMenuVisible = true,
  hideTopMenu = false,
}) => {
  let headTopMenuData = [];
  if (headerTopMenuData && headerTopMenuData?.allIsoMainMenus?.length > 0) {
    headTopMenuData = headerTopMenuData?.allIsoMainMenus;
  }
  const [isLoading, dispatchLoading] = useReducer(HeaderReducer, {
    openCountry: false,
    openProfile: false,
    openMobileMenu: false,
    openMobileProfile: false,
  });
  const authCtx = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isActive] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  region = authCtx?.region || region;
  language = 'en' || language;

  const router = useRouter();
  useEffect(() => {
    setIsLoggedIn(authCtx.isLoggedIn);
  }, [authCtx.isLoggedIn, authCtx?.region]);

  const countryDropdownHandler = () => {
    dataLayerPush();
    dispatchLoading({ type: 'OPEN_COUNTRY' });
  };

  const dataLayerPush = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'country_dropdown_click',
    });
  };

  const countryDropdownRegionHandler = region => {
    dispatchLoading({ type: 'OPEN_COUNTRY' });
    setCurrentUserRegion(region);
    authCtx?.RegionAndLang(region, language);
  };

  const profileDropdownHandler = () => {
    dispatchLoading({ type: 'OPEN_PROFILE' });
  };
  const profileMobileDropdownHandler = () => {
    dispatchLoading({ type: 'OPEN_MOBILE_PROFILE' });
  };
  const handleClickOutside = () => {
    dispatchLoading({ type: 'CLOSE_DROPDOWN' });
  };

  const mobileMenuHandler = () => {
    dispatchLoading({ type: 'OPEN_MOBILE_MENU' });
  };

  const urlCheck = url => {
    if (
      url.indexOf('/article/') > 0 ||
      url.indexOf('/audio/') > 0 ||
      url.indexOf('/poem/') > 0 ||
      url.indexOf('/video/') > 0
    ) {
      return true;
    }
    return false;
  };

  const renderRegionsList = () => {
    const uniqueRegionsNames = [],
      uniqueRegions = [];
    config.locales.map(locale => {
      if (uniqueRegionsNames.indexOf(locale?.region) === -1) {
        uniqueRegionsNames.push(locale?.region);
        uniqueRegions.push(locale);
      }
    });
    let url;
    return uniqueRegions.map((locale, i) => {
      if (isStaticPage || !availableLocals?.length) {
        const lastPartUrl = router.asPath
          ?.split('/')
          ?.reduce(function (acc, val, index) {
            return index > 2 ? (acc += `/${val}`) : '';
          }, '');
        url =
          isStaticPage ||
          (!availableLocals?.length &&
            `/${locale?.region_sf}/${locale.language_sf}${
              staticPageURL
                ? `/${!urlCheck(staticPageURL) ? staticPageURL : ''}`
                : !urlCheck(lastPartUrl)
                ? lastPartUrl
                : ''
            }`);
        return ListCountries(url, locale, i);
      } else {
        let url = '';
        // Check if the config region and language is present in availableLocals
        // We do this because we need the respective url of the rendered page
        availableLocals.forEach((available_local, i) => {
          const available_local_values =
            available_local &&
            available_local.value &&
            available_local.value?.split('/');
          const available_local_region = available_local_values[1];
          const available_local_language = available_local_values[2];
          if (
            available_local_region == locale.region_sf &&
            available_local_language == locale.language_sf
          ) {
            url = available_local.value;
          }
        });
        // If the url is not available, push the default home page url.
        !url && (url = `/${locale?.region_sf}/${locale.language_sf}`);

        return ListCountries(url, locale, i);
      }
    });
  };

  const ListCountries = (url, locale, i) => {
    return (
      <ListItem
        display="block"
        key={i}
        listStyleType="none"
        onClick={() => countryDropdownHandler()}
      >
        <NextLink href={url} passHref prefetch={false} legacyBehavior>
          <a>
            <Box
              display="block"
              fontSize={{ base: '16px', lg: '14px' }}
              lineHeight={{ base: '20px', lg: '2' }}
              color="#cf4520"
              textDecoration="none"
              p={{ base: '20px 0 20px 30px', lg: '5px 15px' }}
              m={{ base: '0', lg: '4px auto' }}
              fontWeight="300"
              fontFamily="'FedraSansStd-book', sans-serif"
              _hover={{ bgColor: '#e4ded4' }}
              onClick={
                isMobile
                  ? () => dispatchLoading({ type: 'OPEN_MOBILE_MENU' })
                  : null
              }
              cursor="pointer"
            >
              {locale.region}
            </Box>
          </a>
        </NextLink>
      </ListItem>
    );
  };

  const loginURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}&action=0`;
  return !hideTopMenu ? (
    <Box
      as="header"
      className="sadhguru_header test1"
      onBlur={() => {
        handleClickOutside();
      }}
    >
      {page === 'Exclusive' && (
        <Box
          w="100%"
          h="35px"
          bgColor="rgba(0, 0, 0, 0.8)"
          display={{ base: 'block', xl: 'none' }}
        >
          <Link
            color="#fff"
            fontSize="11px"
            mt="8px"
            cursor="pointer"
            href="/"
            fontFamily="FedraSansStd-book"
            pl="5px"
            pos="absolute"
          >
            <Image
              loading="lazy"
              alt="arrow"
              src={`${config.staticPath}/d/46272/1667577514-icon_left_arrow_black.png`}
              pr="5px"
              display="inline-block"
            />
            Back to Home Page
          </Link>
        </Box>
      )}
      <Box
        w="100%"
        h="38px"
        bgColor={page === 'Samskriti' ? '#484245' : '#E0D7C8'}
        display={{ base: 'none', xl: 'block' }}
      >
        <Box w="1200px" m="0 auto" p="0 15px">
          {page === 'Exclusive' && (
            <Link
              color="#000"
              fontSize="12px"
              mt="12px"
              pos="absolute"
              cursor="pointer"
              href="/"
              fontFamily="FedraSansStd-book"
            >
              <Image
                loading="lazy"
                alt="arrow"
                src={`${config.staticPath}/d/46272/1667577514-icon_left_arrow_black.png`}
                pr="5px"
                display="inline-block"
              />
              Back to Home Page
            </Link>
          )}
          {page === 'Samskriti' && (
            <Link
              color="#fff"
              fontSize="12px"
              mt="12px"
              pos="absolute"
              cursor="pointer"
              href="/"
              fontFamily="FedraSansStd-book"
            >
              <Image
                loading="lazy"
                alt="arrow"
                src={`${config.staticPath}/d/46272/1648229215-back-line-arrow.svg`}
                pr="5px"
              />
              Back to Home Page
            </Link>
          )}

          <Box display={'flex'} justifyContent={'end'}>
            {
            headTopMenuData != '' && page != 'Exclusive' && (
              <>
                <Box w="auto" fontFamily="FedraSansStd-medium">
                  <UnorderedList
                    color={page === 'Samskriti' ? '#ffffff' : '#797160'}
                    fontSize="12px"
                    padding="10px 0 0 0"
                  >
                    <ListItem display={'inline'}>
                      <NextLink
                        href={refineUrlDomain(
                          headTopMenuData[0]?.url,
                          region,
                          language
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',

                          }}
                          alt={headTopMenuData[0]?.title}
                        >
                          <Box float="left"><LazyLoadImageComponent
                            src={headTopMenuData[0]?.icon?.url}
                            alt={headTopMenuData[0]?.title}
                            margin="0 9px 0 0"
                            position="relative"
                            top="2px"
                            minWidth="16px"
                          /></Box>
                         <Box float="left" margin="0 25px 0 0"> {headTopMenuData[0]?.title} </Box>
                        </Link>
                      </NextLink>
                    </ListItem>
                    <ListItem display={'inline'}>
                      <NextLink
                        href={refineUrlDomain(
                          headTopMenuData[1]?.url,
                          region,
                          language
                        )}
                        passHref
                        alt={headTopMenuData[1]?.title}
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',
                          }}
                          alt={headTopMenuData[1]?.title}
                        >
                          <Box float="left"><LazyLoadImageComponent
                            src={headTopMenuData[1]?.icon?.url}
                            alt={headTopMenuData[1]?.title}
                            margin="0 9px 0 0"
                            position="relative"
                            top="-2px"
                            minWidth="19px"
                          /></Box>
                          <Box float="left" margin="0 25px 0 0">{headTopMenuData[1]?.title}</Box>
                        </Link>
                      </NextLink>
                    </ListItem>
                    <ListItem display={'inline'}>
                      <NextLink
                        href={refineUrlDomain(
                          headTopMenuData[2]?.url,
                          region,
                          language
                        )}
                        passHref
                        alt={headTopMenuData[2]?.title}
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',
                          }}
                          alt={headTopMenuData[2]?.title}
                        >
                          <Box float="left"><LazyLoadImageComponent
                            src={headTopMenuData[2]?.icon?.url}
                            alt={headTopMenuData[2]?.title}
                            margin="0 9px 0 0"
                            position="relative"
                            top="-2px"
                            minWidth="17px"
                          /></Box>
                          <Box float="left" margin="0 25px 0 0">{headTopMenuData[2]?.title}</Box>
                        </Link>
                      </NextLink>
                    </ListItem>
                    <ListItem display={'inline'}>
                      <NextLink
                        href={refineUrlDomain(
                          headTopMenuData[3]?.url,
                          region,
                          language
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',
                          }}
                          alt={headTopMenuData[3]?.title}
                        >
                          <Box float="left"><LazyLoadImageComponent
                            src={headTopMenuData[3]?.icon?.url}
                            alt={headTopMenuData[3]?.icon?.title}
                            margin="0 9px 0 0"
                            position="relative"
                            top="-2px"
                            minWidth="19px"
                          /></Box>
                          <Box float="left" margin="0 25px 0 0">{headTopMenuData[3]?.title}</Box>
                        </Link>
                      </NextLink>
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Box w="auto">
                  <NextLink
                    href={refineUrlDomain(
                      headTopMenuData[4]?.url,
                      region,
                      language
                    )}
                    passHref
                    legacyBehavior
                  >
                    <Link
                      padding="2px 0"
                      cursor="pointer"
                      title={headTopMenuData[4]?.icon?.alt}
                    >
                      <Box
                        w="213px"
                        height="39px"
                        display="block"
                        background={`url('${headTopMenuData[4]?.icon?.url}')`}
                        backgroundRepeat="no-repeat"
                        _hover={{
                          background: `url('${headTopMenuData[4]?.iconHover?.url}')`,
                        }}
                      ></Box>
                    </Link>
                  </NextLink>
                </Box>
              </>
            )}
            {page != 'Exclusive' && (
              <>
                <Box pos="relative" zIndex="9" userSelect="none" m="0 0 0 19px">
                  {!isNonRegionalized(router.asPath, authCtx.lang) && (
                    <>
                      <Box
                        float="left"
                        w="auto"
                        onClick={() => countryDropdownHandler()}
                        background={`url(${
                          isLoading.openCountry
                            ? `${headTopMenuData[5]?.iconHover?.url}`
                            : `${headTopMenuData[5]?.icon?.url}`
                        }) no-repeat left center`}
                        padding="0 7px 0 7px"
                        id="countryDropdown"
                        _hover={{
                          background: `url('${headTopMenuData[5]?.iconHover?.url}') no-repeat left center`,
                        }}
                        className={
                          isLoading.openCountry ? 'activeCountryDropdown' : ''
                        }
                      >
                        <Box
                          textDecoration="none"
                          p="12px 13px 11px 14px"
                          bgSize="10px"
                          bgRepeat="no-repeat"
                          bgPos="90% 45%"
                          textTransform="uppercase"
                          cursor="pointer"
                          float="left"
                          fontSize="11px"
                          color="#8a806f"
                          fontFamily="'FedraSansStd-medium', sans-serif"
                          _hover={{
                            color: '#cf4520',
                          }}
                        >
                          {/* {currentUserRegion} */}
                          <Box
                            as="i"
                            color="#797160"
                            border="solid #797160"
                            borderWidth="0 2.5px 2.5px 0"
                            display="inline-block"
                            padding="2px"
                            position="relative"
                            top="-1px"
                            left="7px"
                            mr="8px"
                            transform={
                              isLoading.openCountry
                                ? 'rotate(225deg)'
                                : 'rotate(45deg)'
                            }
                          ></Box>
                        </Box>
                      </Box>
                      <Box
                        pos="absolute"
                        w="135px"
                        right="-25px"
                        top="37px"
                        pt="2px"
                        opacity={`${isLoading.openCountry ? '1' : ''}`}
                        display={`${isLoading.openCountry ? 'block' : 'none'}`}
                      >
                        <Box
                          float="left"
                          w="100%"
                          bgColor="#F6F7F9"
                          borderRadius="4px"
                          border="1px solid #f0f0f0"
                          boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
                          p="0 5px"
                        >
                          <UnorderedList m="0" p="0">
                            {renderRegionsList()}
                          </UnorderedList>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>

                {isLoggedIn && !isStaticPage && (
                  <LoggedIn
                    profileDropdownHandler={profileDropdownHandler}
                    isOpenProfile={isLoading.openProfile}
                    region={region}
                    headTopMenuData={headTopMenuData}
                    page={page}
                  />
                )}
                {!isLoggedIn && !isStaticPage && (
                  <NotLoggedIn headTopMenuData={headTopMenuData} page={page} />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {page !== 'Exclusive' && page !== 'Samskriti' && secondMenuVisible && (
        <DesktopMenu
          key="22"
          headerMenuData={headerMenuData}
          headTopMenuData={headTopMenuData}
          region={region}
          language={language}
          isStaticPage={isStaticPage}
        >
          {' '}
        </DesktopMenu>
      )}
      {page !== 'Exclusive' && page !== 'Samskriti' && secondMenuVisible && (
        <MobileMenu
          headerMenuData={headerMenuData}
          headTopMenuData={headTopMenuData}
          countryDropdownHandler={countryDropdownHandler}
          currentUserRegion={currentUserRegion}
          renderRegionsList={renderRegionsList}
          isOpenCountry={isLoading.openCountry}
          mobileMenuHandler={mobileMenuHandler}
          isOpenMobMenu={isLoading.openMobileMenu}
          urlLogin={loginURL}
          countryDropdownRegionHandler={countryDropdownRegionHandler}
          isLoggedIn={isLoggedIn}
          profileMobileDropdownHandler={profileMobileDropdownHandler}
          isOpenProfile={isLoading.openMobileProfile}
          region={region}
          language={language}
          isStaticPage={isStaticPage}
          isActive={isActive}
        />
      )}

      <style jsx global>{`
        #countryDropdown ul {
          width: 86px;
          margin-right: 0;
          margin-left: 0;
        }
        #countryDropdown li a {
          color: #cc4b21;
        }
        #countryDropdown li a:hover div {
          background: none;
        }
      `}</style>
    </Box>
  ) : null;
};

export default Header;
